import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, message, Form, Input, Upload, Select } from 'antd';
import ImgCrop from 'antd-img-crop';
import IconDelete from 'src/assets/icon/IconDelete';
import { useMutation, useQueryClient } from 'react-query';
import { NumericFormat } from 'react-number-format';
import IconUpload from 'src/assets/icon/IconUpload';
import { useListSelectAcrylicPlate } from 'src/api/useListSelectAcrylicPlate';
import { useListSizeAcrylicPlate } from 'src/api/useListSizeAcrylicPlate';
import { TemplateType, useAddTemplate } from 'src/api/useAddTemplate';
import { useEditTemplate } from 'src/api/useEditTemplate';
import IconPreviewImage from 'src/assets/icon/IconPreviewImage';
import IconDownloadImage from 'src/assets/icon/IconDownloadImage';
import IconEditImage from 'src/assets/icon/IconEditImage';
import Progress from 'src/components/common/progress';
import IconFirst from 'src/assets/icon/IconFirst';
import IconSecend from 'src/assets/icon/IconSecend';
import IconThird from 'src/assets/icon/IconThird';
import ModalPreviewFile from 'src/components/common/modal/modalPreviewFile';
import useItem from 'src/hook/useItem';
import { deepClone } from 'src/utils';

interface TemplateInfoGeneralProps {
  thumbnailUrl?: string;
  imageUrl?: string;
  background?: any;
  thumbnailFile?: any;
  imageFile?: any;
  setThumbnailFile?(val: any): void;
  setThumbnailUrl?(val: any): void;
  infoGeneralTemplate?: any;
  setInfoGeneralTemplate?(val: any): void;
}

const TemplateInfoGeneralCreateEditComponent: React.FC<TemplateInfoGeneralProps> = ({
  thumbnailUrl = '',
  imageUrl,
  background,
  thumbnailFile,
  imageFile,
  infoGeneralTemplate,
  setInfoGeneralTemplate,
  setThumbnailFile,
  setThumbnailUrl,
}: TemplateInfoGeneralProps) => {
  const [antForm] = Form.useForm();
  const { clearItems } = useItem();
  const STEPS_TEMPLATE = [
    {
      icon: <IconFirst />,
      name: '基本情報',
      processing: 1,
    },
    {
      icon: <IconSecend />,
      name: '詳細情報  ',
      processing: 2,
    },
    {
      icon: <IconThird />,
      name: '確認',
      processing: 3,
    },
  ];
  const ratioImageDesign = Number(infoGeneralTemplate?.height) / Number(infoGeneralTemplate?.width);
  const heightImageDesign = 300 * ratioImageDesign;
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [imageMessError, setImageMessError] = useState<string>('');
  const [idAcrylicPlateSelected, setIdAcrylicPlateSelected] = useState<any>();
  const acrylicPlateSizeIdCurrent = Form.useWatch('acrylic_plate_size_id', antForm);
  const priceCurrent = Form.useWatch('price', antForm);
  const shippingFeeCurrent = Form.useWatch('shipping_fee', antForm);
  const nameCurrent = Form.useWatch('name', antForm);
  const isConfirmPage = location.pathname?.includes('confirm') && infoGeneralTemplate;
  const disabledEditData = !!isConfirmPage || !!id;
  const [isOpenModalPreviewImage, setIsOpenModalPreviewImage] = useState<boolean>(false);
  const { stageData } = useItem();
  const [svgImage, setSvgImage] = useState<any>(null);

  const queryClient = useQueryClient();
  const { data: dataSelectAcrylicPlate } = useListSelectAcrylicPlate();
  const { data: dataSelectSizeAcrylicPlate } = useListSizeAcrylicPlate(idAcrylicPlateSelected);
  const newDataSelectAcrylicPlate = dataSelectAcrylicPlate?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.title,
    };
  });
  const newDataSelectSizeAcrylicPlate = dataSelectSizeAcrylicPlate?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.size_title,
    };
  });

  const handleGoBack = () => {
    if (isConfirmPage) {
      history(-1);
    } else {
      history(id ? `/product/templates/detail/${id}` : '/product/templates');
    }
  };

  const { isLoading: isLoadingTemplate, mutate: onCreateTemplate } = useMutation(
    (param: TemplateType) => useAddTemplate(param),
    {
      onSuccess: () => {
        message.success('テンプレート登録に成功しました');
        history('/product/templates');
        antForm.resetFields();
        clearItems();
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const { isLoading: isLoadingEdit, mutate: onEditTemplate } = useMutation(
    (param: TemplateType) => useEditTemplate(param, Number(id)),
    {
      onSuccess: () => {
        message.success('テンプレート情報の編集に成功しました/ ');
        history(`/product/templates`);
        antForm.resetFields();
        queryClient.clear();
        clearItems();
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const handleCreateEditPart = async () => {
    const valueForm = antForm.getFieldsValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    if (!onCheckDisableBtnSubmit()) {
      const clonedData = deepClone(stageData);
      if (id) {
        const param: any = {
          name: valueForm?.name,
          acrylic_plate_size_id: valueForm?.acrylic_plate_size_id,
          shipping_fee: valueForm?.shipping_fee,
          price: valueForm?.price,
          background: background,
          editor_content: JSON.stringify(clonedData),
          ...(!!thumbnailFile ? { thumbnail: thumbnailFile } : {}),
          ...(!!imageFile ? { image: imageFile } : {}),
        };
        if (clonedData?.length === 0) {
          delete param['editor_content'];
        }
        onEditTemplate(param);
      } else {
        if (isConfirmPage) {
          onCreateTemplate({
            name: valueForm?.name,
            acrylic_plate_size_id: valueForm?.acrylic_plate_size_id,
            shipping_fee: valueForm?.shipping_fee,
            price: valueForm?.price,
            thumbnail: thumbnailFile,
            image: imageFile,
            background: background,
            editor_content: JSON.stringify(clonedData),
          });
        } else {
          setInfoGeneralTemplate?.(valueForm);
          history('/product/templates/create/design');
        }
      }
    }
  };

  const beforeUpload = async (file: any) => {
    antForm.setFields([
      {
        name: 'thumbnail',
        errors: [],
      },
    ]);
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (RegExp(/(jpg|jpeg|png)/i).test(`${file.type} ${file?.name}`)) {
      if (!isLt5MB) {
        setImageMessError('ファイルサイズが5MBを超えています');
        return;
      } else {
        const url = window.URL.createObjectURL(file);
        setThumbnailUrl?.(url);
        setThumbnailFile?.(file);
        setImageMessError('');
      }
    } else {
      setImageMessError('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください');
      return;
    }
    return false;
  };

  const validateTitle = (rule: any, value: string, callback: any) => {
    if (!value?.trim()) {
      callback(`テンプレート名を入力してください`);
    } else if (value?.trim()?.length > 50) {
      callback(`テンプレート名は50文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const onCheckDisableBtnSubmit = () => {
    const validateErrorValueList =
      !thumbnailUrl ||
      !nameCurrent ||
      !!imageMessError ||
      !acrylicPlateSizeIdCurrent ||
      Number(priceCurrent || 0) === 0 ||
      Number(shippingFeeCurrent || 0) === 0;
    return validateErrorValueList;
  };

  const handleDeleteThumbnail = () => {
    setThumbnailUrl?.('');
    setImageMessError('');
    antForm.setFields([
      {
        name: 'thumbnail',
        errors: ['ファイルをアップロードしてください'],
      },
    ]);
  };

  const validateNumber = (rule: any, value: string, callback: any, errorNotValue: any) => {
    if (!String(value)?.trim()) {
      callback(errorNotValue);
    } else if (Number(String(value)?.trim()) === 0) {
      callback(`0以外の値を入力してください`);
    } else {
      callback();
    }
  };

  const uploadButton = (
    <button className="upload-file" type="button">
      <IconUpload />
      <p className="text-upload">ファイルアップロード</p>
    </button>
  );

  useEffect(() => {
    if (infoGeneralTemplate) {
      antForm.setFieldsValue(infoGeneralTemplate);
      setIdAcrylicPlateSelected(infoGeneralTemplate?.acrylic_plate);
    } else {
      if (!id) {
        history('/product/templates/create/info-general');
      }
    }
  }, [infoGeneralTemplate]);

  useEffect(() => {
    const imageUrlRender = imageUrl || infoGeneralTemplate?.image_url;
    if (imageUrlRender) {
      try {
        fetch(imageUrlRender, {
          method: 'GET',
          headers: {
            pragma: 'no-cache',
            'cache-control': 'no-cache',
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.readAsText(blob);
            reader.onloadend = () => {
              const svgString = reader?.result as any;
              setSvgImage(svgString);
            };
          });
      } catch (err) {}
    }
  }, [imageUrl, infoGeneralTemplate?.image_url]);

  return (
    <div className="page-content company-page">
      <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
        <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
          <div className="mr-[17px]">
            <IconArrowLeft />
          </div>
          <span className="line-1">{'テンプレートの追加'}</span>
        </div>
        <div></div>
      </div>
      <Form form={antForm} className="form-create w-[900px] mx-auto" layout="vertical" autoComplete="off">
        <Progress
          status={isConfirmPage || id ? 3 : 1}
          stepsOption={STEPS_TEMPLATE}
          classNameStepper="stepper-item-template"
        />
        <div className="pb-[12px] pt-[30px]">
          <div className="mb-[16px]">
            <Form.Item
              required
              label="画像のサムネイル"
              name="thumbnail"
              rules={[
                {
                  required: !thumbnailUrl,
                  message: 'ファイルをアップロードしてください',
                },
              ]}
            >
              <div className="view-img-crop">
                <ImgCrop
                  showGrid
                  showReset
                  rotationSlider
                  aspect={4 / 5}
                  modalTitle="サムネイル画像のトリミング"
                  modalOk="トリミング"
                  modalCancel="キャンセル"
                  resetText="リセット"
                >
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    multiple={false}
                    maxCount={1}
                    beforeUpload={(file) => beforeUpload(file)}
                    className="upload-file-image upload-file-plate"
                  >
                    {thumbnailUrl ? (
                      <img src={thumbnailUrl} alt="thumbnail" className="thumbnail-upload" />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
                {thumbnailUrl && (
                  <button className="icon-delete-plate" onClick={handleDeleteThumbnail} type="button">
                    <IconDelete />
                  </button>
                )}
              </div>
            </Form.Item>
            {imageMessError && <p className="invalid-feedback whitespace-pre-line">{imageMessError}</p>}
          </div>
          <div className={`mb-[16px]`}>
            <Form.Item
              required
              label="テンプレート名"
              name="name"
              rules={[{ validator: (rule, value, callback) => validateTitle(rule, value, callback) }]}
            >
              <Input className="input-form" placeholder="テンプレート名を入力してください" />
            </Form.Item>
          </div>

          <div className="mt-[30px] bg-blue pt-[10px] pb-[20px] pr-[26px] pl-[26px]">
            <Form.Item
              required
              label="アクリル種類"
              name="acrylic_plate"
              className="pb-[20px]"
              rules={[{ message: '', required: true }]}
            >
              <Select
                className={`select-type-part ${!!disabledEditData ? 'select-part-disable' : ''}`}
                options={newDataSelectAcrylicPlate || []}
                placeholder="アクリル種類を選択してください"
                onChange={(data: any) => {
                  setIdAcrylicPlateSelected(data);
                  antForm.setFieldsValue({
                    height: null,
                    width: null,
                    thickness: null,
                    acrylic_plate_size_id: null,
                  });
                }}
                disabled={disabledEditData}
              />
            </Form.Item>
            <Form.Item
              required
              label="サイズ"
              name="acrylic_plate_size_id"
              className="pb-[20px]"
              rules={[{ message: '', required: true }]}
            >
              <Select
                className={`select-type-part ${!!disabledEditData ? 'select-part-disable' : ''}`}
                options={newDataSelectSizeAcrylicPlate || []}
                placeholder="サイズを選択してください"
                onChange={(idSizeAcrylic: any) => {
                  const infoSizeAcrylicSelected = dataSelectSizeAcrylicPlate?.find(
                    (item: any) => item?.id === idSizeAcrylic
                  );
                  antForm.setFieldsValue({
                    height: infoSizeAcrylicSelected?.height,
                    width: infoSizeAcrylicSelected?.width,
                    thickness: infoSizeAcrylicSelected?.thickness,
                  });
                }}
                disabled={disabledEditData}
              />
            </Form.Item>
            <div className="flex items-top gap-[30px]">
              <Form.Item required label="高さ" name={'height'} className="w-[257px] error-form-acrylic">
                <Input className="input-form" placeholder="ー" readOnly disabled />
              </Form.Item>
              <Form.Item required label="幅" name={'width'} className="w-[257px] error-form-acrylic">
                <Input className="input-form" placeholder="ー" readOnly disabled />
              </Form.Item>
              <Form.Item required label="厚さ" name={'thickness'} className="w-[257px] error-form-acrylic">
                <Input className="input-form" placeholder="ー" readOnly disabled />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="mb-[35px] flex items-top gap-[30px] mt-[20px] bg-blue pt-[10px] pb-[30px] pr-[26px] pl-[26px]">
          <Form.Item
            required
            label="単価（円）"
            name={'price'}
            className="w-[257px] error-form-acrylic"
            rules={[
              {
                validator: (rule, value, callback) => validateNumber(rule, value, callback, `単価を入力してください`),
              },
            ]}
          >
            <NumericFormat
              pattern="[0-9]*"
              className="number-input"
              style={{ width: '100%' }}
              allowNegative={false}
              decimalScale={0}
              placeholder="例：500"
              maxLength={10}
            />
          </Form.Item>
          <Form.Item
            required
            label="配送料（円）"
            name={'shipping_fee'}
            className="w-[257px] error-form-acrylic"
            rules={[
              {
                validator: (rule, value, callback) => validateNumber(rule, value, callback, `配送料を入力してください`),
              },
            ]}
          >
            <NumericFormat
              pattern="[0-9]*"
              className="number-input"
              style={{ width: '100%' }}
              allowNegative={false}
              decimalScale={0}
              placeholder="例：250"
              maxLength={10}
            />
          </Form.Item>
        </div>

        {(isConfirmPage || id) && (
          <div className="mb-[50px] flex items-top gap-[30px] mt-[20px] bg-blue pt-[10px] pb-[30px] pr-[26px] pl-[26px] wrap-design-image-template-preview">
            <div>テンプレート画像</div>

            <div className="image-template-preview">
              <div className="cover-image-template">
                {svgImage && (
                  <div
                    // style={{ width: WIDTH_STAGE * (75 / HEIGHT_STAGE) }}
                    dangerouslySetInnerHTML={{ __html: svgImage }}
                  />
                )}
              </div>

              {isConfirmPage ? (
                <div className="flex items-top gap-[20px] ">
                  <div
                    onClick={() => {
                      setIsOpenModalPreviewImage(true);
                    }}
                    className="icon-action"
                  >
                    <IconPreviewImage />
                  </div>
                  <div
                    onClick={() => {
                      const a = document.createElement('a');
                      a.download = 'image.png';
                      a.href = imageUrl!;
                      a.click();
                    }}
                    className="icon-action"
                  >
                    <IconDownloadImage />
                  </div>
                </div>
              ) : (
                <div onClick={() => history(`/product/templates/edit/${id}/design`)} className="icon-action">
                  <IconEditImage />
                </div>
              )}
            </div>
          </div>
        )}
        <div className={`footer-form flex`}>
          <Button className="button-form mr-[16px]" onClick={handleGoBack}>
            {location.pathname?.includes('edit')
              ? '詳細画面へ戻る'
              : isConfirmPage
              ? '<<詳細画面へ戻る'
              : '一覧画面へ戻る'}
          </Button>
          <Button
            disabled={onCheckDisableBtnSubmit()}
            className="button-form button-form-Submit"
            onClick={handleCreateEditPart}
            loading={isLoadingTemplate || isLoadingEdit}
          >
            <span></span>
            {location.pathname?.includes('edit') ? 'OK' : isConfirmPage ? '完了' : '詳細情報画面へ'}
          </Button>
        </div>
      </Form>
      <ModalPreviewFile
        isShowModal={isOpenModalPreviewImage}
        onCancel={() => {
          setIsOpenModalPreviewImage(false);
        }}
        width={300}
        svgImage={svgImage}
      />
    </div>
  );
};

export default TemplateInfoGeneralCreateEditComponent;
